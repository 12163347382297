<template>
  <div class="carousel-box" >
    <div class="aiz-card-box border border-light rounded hov-shadow-md mt-1 mb-2 has-transition bg-white" @click="toDetail">
      <span class="badge-custom"  v-show="!hideIcon && goodsInfo.percent">{{ $t('li-kai') }}<span class="box ml-1 mr-0">&nbsp;{{goodsInfo.percent}}%</span></span>
      <div class="position-relative">
        <a  class="d-block">
          <img  class="img-fit mx-auto h-140px h-md-210px ls-is-cached lazyloaded" :src="goodsInfo.MainImage"  >
        </a>
        <span v-if="goodsInfo.wholesale && !hideIcon" class="absolute-bottom-left fs-11 text-white fw-600 px-2 lh-1-8" style="background-color: #455a64">
          {{ $t('pi-fa') }} </span>
        <div class="absolute-top-right aiz-p-hov-icon" v-show="!hideIcon">
          <a href="javascript:void(0)"  :title="$t('tian-jia-dao-yuan-wang-qing-dan')" @click.stop="addWish">
            <i class="la la-heart-o"></i>
          </a>
          <a href="javascript:void(0)"  :title="$t('tian-jia-dao-gou-wu-che')" @click.stop="addShipping">
            <i class="las la-shopping-cart"></i>
          </a>
        </div>
      </div>
      <div class="p-md-3 p-2 text-left">
        <div class="fs-15">
            <span class="fw-700 text-primary">{{resetPrice(goodsInfo.SalePrice)}}</span>
        </div>
        <h3 class="fw-600 fs-13 text-truncate-2 lh-1-4 mb-0 h-35px">
            <a  class="d-block text-reset">{{goodsInfo.ProductTranslation ? goodsInfo.ProductTranslation[0].Name : ''}}</a>
        </h3>
      </div>
    </div>

   
  </div>
</template>
<script>
import { Popup } from 'vant'
import { addCart, getCartList } from '@/api/index'
import { resetPrice } from '@/utils/common'
import { getUserType } from '@/utils/auth'
export default {
  components: {
    Popup
  },
  data() {
    return {
      showPopup: false
    }
  },
  props: {
    goodsInfo: Object,
    hideIcon: Boolean,
    hideIntegral: Boolean,
  },
  computed: {
    isLogin() {
      return this.$store.state.isLogin
    },
    userType() {
      return getUserType()
    }
  },
  methods: {
    resetPrice,
    toDetail() {
      sessionStorage.setItem('goodsData', JSON.stringify(this.goodsInfo))
      this.$router.push({
        name: 'product',
        query: {
          id: this.goodsInfo.ID
        }
      })
    },
    addCompare() {
      
    },
    addWish(){
      if (this.userType == 'seller') {
        this.$toast.fail('商家不支持收藏商品')
        return
      }
      let form = new FormData()
      form.append('GoodsId', this.goodsInfo.ID)
      form.append(this.$t('attribute'), 2)
      addCart(form).then(res => {
        if (res.code == 0) {
          this.$toast(this.$t('tian-jia-cheng-gong'))
          this.initCart()
        } else {
          this.$toast.fail(res.msg)
        }
      })
    },
    initCart() {
      let form = new FormData()
      form.append('current', 1)
      form.append('pageSize', 100)
      getCartList(form).then(res => {
				this.$store.state.cartList = res.data.Items.filter(v => {
          return v.Attribute == 1
        })
				this.$store.state.wishList = res.data.Items.filter(v => {
          return v.Attribute == 2
        })
			})
    },
    addShipping() {
      if (this.isLogin) {
        if (this.userType == 'seller') {
          this.$toast.fail('商家不支持收藏商品')
          return
        }
        this.$store.state.openCart = true
        this.$store.state.goodsInfo = this.goodsInfo
      } else {
        this.$router.push({
          name: 'login'
        })
      }
    }
  }
}
</script>